






















import { Component, Vue, Prop } from 'vue-property-decorator';

import { ListTypes } from './interface';

@Component
export default class PlanTypes extends Vue {
  @Prop() listTypes!: ListTypes[];

  get activeType() {
    return this.$store.getters.activePlanTypes;
  }

  setActivePlanTypes(type: number) {
    this.$store.commit('setActivePlanTypes', type);
  }
}
