

























import { Component, Vue, Watch } from 'vue-property-decorator';

import CardPlanBanner from '../CardPlanBanner/index.vue';
import CardPlanBannerLoading from '../CardPlanBanner/CardPlanBannerLoading.vue';

import PlansService from '@/services/Plans/PlansService';

import { Plan, Discount } from '../../interfaces/IShopping';

import { TYPE_DISCOUNT } from '../../constants/index';

import { getPriceDiscount } from '@/share/Util/Price/index';

@Component({
  components: {
    CardPlanBanner,
    CardPlanBannerLoading
  }
})
export default class Banner extends Vue {
  private isLoadingPlan = false;

  private TYPE_DISCOUNT = TYPE_DISCOUNT;

  private plans!: Array<Plan>;
  private currentPlan: Plan | null = null;

  private PlansService = new PlansService();

  created() {
    this.getPlans();
  }

  get activeType() {
    return this.$store.getters.activePlanTypes;
  }

  async getPlans() {
    try {
      this.isLoadingPlan = true;

      const response = await this.PlansService.getPlans({
        isHighlight: 1
      });

      if (response && response?.plans) {
        this.plans = response.plans;
      }

      this.setCurrentPlan();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o plano.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingPlan = false;
    }
  }

  @Watch('activeType')
  setCurrentPlan() {
    if (!this.activeType || !this.plans.length) return;

    this.currentPlan = this.plans.find((plan: Plan) => plan.planIntervalCount === this.activeType) || null;
  }

  handlePriceDiscount(plan: Plan, type: string) {
    const totalPrice = plan.planPrice;

    const foundDiscountPrice = plan.discounts.find((discount: Discount) => discount.code === type);

    if (!foundDiscountPrice) return totalPrice;

    let price = 0;

    if (foundDiscountPrice.percent) {
      price = totalPrice * (foundDiscountPrice.percent / 100) > foundDiscountPrice.max_value
        ? totalPrice - foundDiscountPrice.max_value
        : totalPrice - totalPrice * (foundDiscountPrice.percent / 100);
    }

    if (foundDiscountPrice.value) {
      price = totalPrice - foundDiscountPrice.value;
    }

    return price / plan.planInstallments;
  }

  getPixPrice() {
    if (!this.currentPlan || !this.currentPlan.discounts.length) return 0;

    return getPriceDiscount(TYPE_DISCOUNT.PIX, this.currentPlan);
  }

  handleClickProduct(id: number) {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: String(id)
      }
    });
  }
}
