import { render, staticRenderFns } from "./CardPlanLoading.vue?vue&type=template&id=60cc7779&scoped=true&"
import script from "./CardPlanLoading.vue?vue&type=script&lang=ts&"
export * from "./CardPlanLoading.vue?vue&type=script&lang=ts&"
import style0 from "./CardPlanLoading.scss?vue&type=style&index=0&id=60cc7779&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60cc7779",
  null
  
)

export default component.exports