
































import {
  Component, Vue, Watch, Prop 
} from 'vue-property-decorator';

import FeedbackUser from '@/components/FeedbackUser/index.vue';

import MenuPlan from '../MenuPlan/MenuPlan.vue';
import CardPlan from '../CardPlan/CardPlan.vue';
import CardPlanLoading from '../CardPlanLoading/CardPlanLoading.vue';

import { Plan, Discount } from '../../interfaces/IShopping';

import { TYPE_DISCOUNT } from '../../constants/index';

import { DEFAULT_INTERVAL_COUNT } from './constants';

import { getPriceDiscount } from '@/share/Util/Price/index';

@Component({
  components: {
    MenuPlan,
    CardPlan,
    CardPlanLoading,
    FeedbackUser
  }
})
export default class ContainerPlans extends Vue {
  @Prop() plans!: Array<Plan>;
  @Prop() isLoadingPlans!: boolean;

  private intervalCount = DEFAULT_INTERVAL_COUNT;
  private TYPE_DISCOUNT = TYPE_DISCOUNT;

  private currentPlans = [] as Array<Plan>;

  get activePlanTypes() {
    return this.$store.getters.activePlanTypes;
  }

  handlePriceDiscount(plan: Plan, type: string) {
    const totalPrice = plan.planPrice;

    const foundDiscountPrice = plan.discounts.find((discount: Discount) => discount.code === type);

    if (!foundDiscountPrice) return totalPrice;

    let price = 0;

    if (foundDiscountPrice.percent) {
      price = totalPrice * (foundDiscountPrice.percent / 100) < foundDiscountPrice.max_value
        ? totalPrice - foundDiscountPrice.max_value
        : totalPrice - totalPrice * (foundDiscountPrice.percent / 100);
    }

    if (foundDiscountPrice.value) {
      price = totalPrice - foundDiscountPrice.value;
    }

    return price;
  }

  @Watch('activePlanTypes', { immediate: true })
  setIntervalCount() {
    if (!this.activePlanTypes) return;

    this.intervalCount = this.activePlanTypes;
  }

  @Watch('intervalCount')
  @Watch('plans', {
    immediate: true,
    deep: true
  })
  setCurrentPlans() {
    if (!this.plans?.length) return;

    this.currentPlans = this.plans.filter(
      (plan: Plan) => plan.planIntervalCount === this.intervalCount && !plan.planHighlight
    );
  }

  getPixPrice(plan: Plan) {
    if (!plan || !plan.discounts.length) return 0;

    return getPriceDiscount(TYPE_DISCOUNT.PIX, plan);
  }

  handleClickProduct(id: number) {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'plano',
        productId: String(id)
      }
    });
  }

  handleClickProductKnowMore(id: number) {
    this.$router.push({
      name: 'Descriptive',
      params: {
        feature: 'planos',
        productId: String(id)
      }
    });
  }
}
