














import { Component, Vue } from 'vue-property-decorator';

import { LIST_INTERVAL } from '../../constants';

import { MenuPlan as IMenuPlan } from '../../interfaces/IShopping';

@Component
export default class MenuPlan extends Vue {
  private menus!: Array<IMenuPlan>;
  private indexActiveMenu = 0;

  created() {
    this.loaderListButton();
  }

  loaderListButton() {
    this.menus = JSON.parse(JSON.stringify(LIST_INTERVAL));
  }

  get getMenus() {
    return this.menus;
  }

  handleButtonMenu(value: number, index: number) {
    this.setActiveMenu(index);

    this.$emit('menu', value);
  }

  setActiveMenu(index: number) {
    this.indexActiveMenu = index;
  }
}
