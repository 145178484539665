










import { Component, Vue, Prop } from 'vue-property-decorator';

import SaleStar from '@/assets/icons/SaleStar.vue';

const DEFAULT_TEXT = 'O mais popular';

@Component({
  components: {
    SaleStar
  }
})
export default class Highlight extends Vue {
  @Prop({ default: DEFAULT_TEXT }) text!: string;
  @Prop({}) backgroundColor!: string;
  @Prop({}) textColor!: string;
}
